import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpStatusService {
  private makingRequest: BehaviorSubject<boolean>;
  constructor() {
    this.makingRequest = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.makingRequest.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.makingRequest.asObservable();
  }
}
