// *** BUMP THIS VERSION ONLY WHEN UPDATING STARTER TEMPLATE ***
// BH Starter Template Version: 8.0.0

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'MYBAYSTATE',

  // App Description (full name)
  appDescription: 'MyBaystate',

  // App Version Number
  appVersion: '8.0.0',

  // App URL Scheme
  appUrlScheme: 'mybaystate:',

  // Web URL
  webUrl: 'https://dev.mybaystate.apps.baystatehealth.org',

  // App's REST Service API Root URL
  apiUrl: 'https://mytest.baystatehealth.org/myhealth/api/v1',

  // AWS Cognito API Root URL
  awsCognitoUrl: 'https://bh-dev-auth.auth.us-east-1.amazoncognito.com',

  // AWS Cognito Client ID for Auth (Needs client ID specific to app)
  awsCognitoClientId: '2qe042l28n359l6ugutf4fn51n',

  // Using SSO Authentication
  ssoEnabled: false,

  // App's Uploaded Files Root URL
  filesUrl: 'https://mytest.baystatehealth.org/myhealth',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://appstore.baystatehealth.org/mybaystate/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // MyB Console API URL
  consoleApiUrl: 'https://mobiledev.api.baystatehealth.org/mybaystate',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' option locks app into light mode, and user cannot change it
  // 'dark' option locks app into dark mode, and user cannot change it
  theme: 'light',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: true,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 300000,

  // Enable Multi-language support
  // Shows the Select Language feature on login page, user menu, and account page
  // Update translator service with support languages
  enableMultiLanguageSupport: true,

  // Banner Sequences
  bannerIds: {
    login: 84,
    home: 99,
    allergy: 159,
    appointment: 170,
    concern: 160,
    healthStat: 157,
    healthCondition: 158,
    goals: 162,
    medicine: 150,
    radiology: 152,
    report: 156,
    prescription: 151,
    share: 171,
    testResult: 154,
    vaccinations: 161,
    visits: 155,
  },

  // Patient Check-in Configuration
  patientCheckIn: {
    apiUrl: 'https://mobiledev.api.baystatehealth.org/patientcheckin',
    apiToken: '2a671352-c429-4622-b727-a1d7b47e2bda',
    locLookupId: 8,
    labLocId: 6,
    radLocId: 7
  },

  // Bill Pay URL
  billPayUrl: 'https://baystatehealth.ci.healthpay24.cloud',

  // Validation Mode
  validationMode: true,

  // External URL resources
  urls: {
    askALibrarian: 'https://www.emailmeform.com/builder/embed/9eJedD8FsLf2hZB',
    payABill: 'https://baystatehealth.ci.healthpay24.cloud/account/login?ReturnUrl=%2F',
    wayToGo: 'https://atobe.app/photo-route?buildingId=26&routeLang=en&channel=GATEWAY&MultiBuilding=26%3B16%3B27%3B15',
    patientsLikeMe: 'https://www.patientslikeme.com/',
    findAProvider: 'https://www.baystatehealth.org/providers?',
    omPractice: 'https://www.ompractice.com/',
    baystateAppStore: 'https://appstore.apps.baystatehealth.org/',
    recordRequest: '/tabs/resources/patient-forms/new/patientportalroi',
    changeRecordRequest: '/tabs/resources/patient-forms/new/ptdemogchng',
    labCorp: 'https://www.Labcorp.com/labs-and-appointments',
    addProxyForm: '/tabs/resources/patient-forms/new/ptportalproxy2020eng'
  },

  filterNavigationForLiteUsers: [
    {
      category: 'HEALTH RECORD',
      name: 'Medicines'
    },
    {
      category: 'HEALTH RECORD',
      name: 'Share health record'
    },
    {
      category: 'HOME',
      name: 'Request a prescription refill'
    },
    {
      category: 'HOME',
      name: 'Share Health Record'
    },
  ],

  filterNavigationForProxyOnly: {
    name: ['Pay a medical bill']
  },

  usefulValues: {
    supportEmail: 'MyBaystate@baystatehealth.org',
    proxyEmail: 'PortalProxy@baystatehealth.org',
    contactPhoneUnformatted: 4133224357,
    contactPhoneFormatted: '413-322-4357',
    schedulingPhoneUnformatted: 4137947364,
    schedulingPhoneFormatted: '413-794-7364',
    faxNumberUnformatted: 4133224346,
    faxNumberFormatted: '413-322-4346',
    appStoreURL:
      'https://itunes.apple.com/us/app/mybaystatehealth/id1149098574?mt=8',
    googlePlayURL:
      'https://play.google.com/store/apps/details?id=org.baystatehealth.mybaystatehealth',
    timeoutInSeconds: 180,
    timeoutWarningInSeconds: 60,
    timeoutInSecondsPrivateDevice: 300,
    timeoutWarningInSecondsPrivateDevice: 180,
  },
};
