import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { Tile } from 'src/app/model/Tile';
import { LockedTileModalComponent } from '../../shared/smc-navigation-grid/locked-tile-modal/locked-tile-modal.component';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-public-resources',
  templateUrl: './public-resources.component.html',
  styleUrls: ['./public-resources.component.scss'],
})
export class PublicResourcesComponent implements OnInit {

  @Input() selectedUser: UserIdentifier;
  environment = environment;
  readonly tiles: Tile[] = [
    // { image: 'SymptomChecker', text: 'Symptom checker', link: '/symptom-checker', isLocked: false },
    // { image: 'AskAQuestion', text: 'Ask a librarian', link: '/authenticated/resources/ask-a-librarian', isLocked: true },
    { image: 'PatientForms', text: 'Patient forms', link: '/authenticated/resources/forms-list', isLocked: true },
    { image: 'MobileApps', text: 'Explore our apps', link: 'https://appstore.apps.baystatehealth.org' },
    { image: 'Dictionary', text: 'Health dictionary', link: '/health-dictionary', isLocked: false },
    { image: 'Providers', text: 'Find a provider', link: 'http://providers.baystatehealth.org/search?filter=networks.network%3AEmployed+Network&sort=networks%2Crelevance&_ga=2.221954904.1725562559.1573167976-1211091091.1570105436' },
    { image: 'PayABill', text: 'Pay a bill', link: environment.PAY_URL },
    { image: 'RequestRecords', text: 'Records request', link: 'https://www.baystatehealth.org/patients/medical-records' },
    // { image: 'CovidSymptoms', text: 'COVID-19 Symptoms', link: 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html' },
    { image: 'LabXrayPatient-icon', text: 'Get in line for <br />lab or radiology work', link: '/patient-checkin/check-in-now' },
    // { image: 'LabWork-icon', text: 'Get in line for lab work', link: '/patient-checkin/check-in-now' },
  ];

  readonly helpTiles: Tile[] = [
    { image: 'Locations', text: 'Browse locations', link: 'https://www.baystatehealth.org/locations', isExternal: true },
    { image: 'MedicalServices', text: 'Medical services', link: 'https://www.baystatehealth.org/services', isExternal: true },
    { image: 'News', text: 'News and more', link: 'https://www.baystatehealth.org/news', isExternal: true },
    { image: 'GiftShop', text: 'Gift shop and online store', link: 'https://www.baystatehealth.org/patients/gift-shops', isExternal: true },
    { image: 'VisitingHours', text: 'Visiting hours', link: 'https://www.baystatehealth.org/patients/visiting', isExternal: true },
  ];

  // readonly connectTiles: Tile[] = [
  //   {image: 'patientslikeme', text: 'Connect with patients like you!', link: 'https://www.patientslikeme.com/'},
  //   {image: 'ompractice', text: 'OM Practice', link: 'https://www.ompractice.com/'},
  //   {image: 'AppleHealth', text: 'Connect Mobile Apps to your Record', isExternal: false, isLocked: true}
  // ];

  readonly connectTiles: Tile[] = [
    { image: 'AppleHealth', text: 'Connect Mobile Apps to your Record', link: "/apple-health-setup", isExternal: false, isLocked: false, iconClass: "apple-health" },
    { image: 'patientslikeme', text: 'PatientsLikeMe', link: 'https://www.patientslikeme.com/', isExternal: true },
    { image: 'ompractice', text: 'OM Practice', link: 'https://www.ompractice.com/', isExternal: true },

  ];

  readonly menuLinks: Tile[] = [
    // { image: 'SymptomChecker', text: 'Symptom Checker', link: '/symptom-checker', isExternal: false, isLocked: false },
    // { image: 'AskAQuestion', text: 'Ask a librarian', link: '/authenticated/resources/ask-a-librarian', isExternal: false, isLocked: true },
    { image: 'PatientForms', text: 'Patient Forms', link: '/authenticated/resources/forms-list', isExternal: false, isLocked: true },
    { image: 'MobileApps', text: 'Explore our apps', link: 'https://appstore.apps.baystatehealth.org', isExternal: true },
    { image: 'Dictionary', text: 'Health Dictionary', link: '/health-dictionary', isExternal: false, isLocked: false },
    { image: 'Providers', text: 'Find a provider', link: 'http://providers.baystatehealth.org/search?filter=networks.network%3AEmployed+Network&sort=networks%2Crelevance&_ga=2.221954904.1725562559.1573167976-1211091091.1570105436', isExternal: true },
    { image: 'PayABill', text: 'Pay a bill', link: environment.PAY_URL, isExternal: true },
    { image: 'RequestRecords', text: 'Records request', link: 'https://www.baystatehealth.org/patients/medical-records', isExternal: true },
    { image: 'Locations', text: 'Browse locations', link: 'https://www.baystatehealth.org/locations', isExternal: true },
    { image: 'MedicalServices', text: 'Medical services', link: 'https://www.baystatehealth.org/services', isExternal: true },
    { image: 'News', text: 'News and more', link: 'https://www.baystatehealth.org/news', isExternal: true },
    { image: 'GiftShop', text: 'Gift shop and online store', link: 'https://www.baystatehealth.org/patients/gift-shops', isExternal: true },
    { image: 'VisitingHours', text: 'Visiting hours', link: 'https://www.baystatehealth.org/patients/visiting', isExternal: true },
    { image: 'patientslikeme', text: 'Connect with patients like you!', link: 'https://www.patientslikeme.com/', isExternal: true },
    { image: 'ompractice', text: 'OM Practice', link: 'https://www.ompractice.com/', isExternal: true },
    { image: 'AppleHealth', text: 'Connect Mobile Apps to your Record', isExternal: false, isLocked: true }
  ];

  constructor(
    private _modalController: ModalController,
    private _router: Router,
    private _modalService: ModalService
  ) { }

  async openInternalLink(link: Tile) {

    if (link.isLocked) {
      this.alertLocked();
    }
    else {
      this._router.navigate([link.link]);
    }

  }

  async alertLocked() {
    const modal = await this._modalController.create({
      component: LockedTileModalComponent,
      cssClass: "modal-tile-locked"
    });
    this._modalService.register(modal);

    return await modal.present();
  }

  async ngOnInit() {
  }

  openLink(tile: Tile) {
    if (tile.isLocked) {
      this.alertLocked();
    } else if (tile.isExternal) {
      // window.open(tile.link, '_blank');
      window.location.href = tile.link;
    } else {
      this.openInternalLink(tile);
    }
  }

}
