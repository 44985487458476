import { UserData } from './../../../models/user-data';
import { ThemeOption } from './../../../models/_core/theme-option';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserState } from './../../../models/_core/user-state';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { AlertController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { Injectable, signal, Signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { StorageService } from '../storage/storage.service';
import moment from 'moment';
import { AuthState } from 'src/app/models/_core/auth-state';
import { SSOTokenResponse } from 'src/app/models/_core/sso-token-response';
import { UserDeviceService } from '../user-device/user-device.service';
import { Browser } from '@capacitor/browser';
import { AwsSettings } from 'src/app/models/_core/aws-settings';
import { AnalyticsService } from '../analytics/analytics.service';
import { User } from 'src/app/models/user';
import { AuthLegacyService } from '../auth-legacy/auth-legacy.service';
import { AuthSsoService } from '../auth-sso/auth-sso.service';
import { LoginRequest } from 'src/app/models/login-request';
import { AuthStatus } from 'src/app/models/auth-status';
import { ProxySwitchReceipt } from 'src/app/models/proxy-switch-receipt';
import { LoginResponse } from 'src/app/models/login-response';
import { ProxyUser } from 'src/app/models/proxy-user';
import { RoleType } from 'src/app/models/role-type';
import { UserRolesResponse } from 'src/app/models/user-roles-response';
import { PersonalCommunityUser } from 'src/app/models/personal-community-user';


/**
 * ID: bh-auth-service
 * Name: BH Auth Service
 * Description: Service used for managing authentication and user state
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Improved open modal + alert handling; improved UX
 * 2022-05-23 - MW - v4: Updated depreciated value/error handling
 * 2022-05-27 - MW - v5: Implemented user state and theme subjects
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  env = environment;
  authUser: BehaviorSubject<User> = new BehaviorSubject(null);
  userState: UserState = {};
  userStateSubject: BehaviorSubject<UserState> = new BehaviorSubject({});
  authStatusSubject: BehaviorSubject<AuthStatus> = new BehaviorSubject(null);
  themeSubject: BehaviorSubject<ThemeOption> = new BehaviorSubject('light');
  userMenuOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mainMenuOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  alertExists: BehaviorSubject<boolean> = new BehaviorSubject(false);
  apiUrl: any;
  timeoutWarningMs = 27000;
  timeoutLogoutMs = 120000;
  inactivitySubject = new BehaviorSubject<number>(0);
  inactivityTimer = null;
  userHasLoggedOutSignal = signal(false);

  //New Inactivity Variables
  inactivityTime: any;
  warningTimer: any;
  private readonly warningTime =
    this.env.usefulValues.timeoutWarningInSecondsPrivateDevice * 1000; // 4.5 minutes in milliseconds
  private readonly logoutTime =
    this.env.usefulValues.timeoutInSecondsPrivateDevice * 1000; // 5 minutes in milliseconds
  userLoggedIn = false;
  monitorUser = false;


  targetUrl = '';
  userInteractionEvents = [
    // 'mousemove',
    'mousedown',
    'touchstart',
    'click',
    'scroll',
    'keypress'
  ];

  lastUserInteractionTime = 0;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService,
    private navService: NavigationService,
    private deviceService: UserDeviceService,
    private analytics: AnalyticsService,
    private authLegacy: AuthLegacyService,
    private authSso: AuthSsoService,
  ) {
    this.getUserStateFromStorage();
    //this.listenForActivity();
  }

  /**
   * Gets Auth User object
   * Recommend subscribing to authUser directly
   */
  getAuthUser(): User {
    const authUser = this.authUser.getValue();
    return authUser ? authUser : {};
  }

  /***
   * Updates Auth User object with provided object
   * @param authUser User object to replace existing value
   */
  setAuthUser(authUser: User) {
    this.authUser.next(authUser);
  }

  /**
   * Gets User State object
   * Recommend subscribing to userStateSubject directly
   */
  getUserState(): UserState {
    return this.userStateSubject.getValue();
  }

  /***
   * Updates User State subject object
   * @param userState User State to update with
   */
  setUserState(userState: UserState) {
    this.userStateSubject.next(userState);
  }

  /**
   * Toggles the menu open and closed
   */
  toggleMenu() {
    const isMenuOpen = this.userMenuOpen.getValue();
    this.userMenuOpen.next(!isMenuOpen);
  }

  /**
   * Gets active theme
   * Recommend subscribing to themeSubject directly
   */
  getTheme(): ThemeOption {
    return this.themeSubject.getValue();
  }

  /***
   * Updates theme subject object
   * @param theme ThemeOption to update with
   */
  setTheme(theme: ThemeOption) {
    this.themeSubject.next(theme);
  }

  listenForActivity() {
    this.userInteractionEvents.forEach(ev =>
      document.addEventListener(ev, this.userInteractionOccurred.bind(this)));
    document.addEventListener('visibilitychange', () => {
      const authUser = this.authUser.getValue();
      if (document.visibilityState == 'visible' && authUser?.userId) {
        this.checkIfSessionValid();
      }
    });
  }

  private userInteractionOccurred() {
    this.lastUserInteractionTime = Date.now();
  }

  private checkIfSessionValid() {
    this.getAuthStatus().subscribe(async authStatus => {
      // if session still valid refresh the session
      if (authStatus.LoggedIn) {
        this.refreshSession();
      } else {
        await this.logout(true, true);
      }
    });
  }

  /***
   * Gets the user's state from storage
   */
  async getUserStateFromStorage() {
    this.userState = await this.storageService.getData('userState');
  }

  /***
   * Save the user's state to local storage
   */
  async saveUserStateToStorage() {
    if (!this.env.storeToken && this.userState.authUser && this.userState.authUser.token) {
      this.userState.authUser.token = null;
    }
    this.userStateSubject.next(this.userState);
    this.storageService.saveData('userState', this.userState);
  }

  /**
   * Starts inactivity timer.
   * Should be called after successfully logging in
   */
  public startInactivityTimer() {
    if (this.env.requireTimeout) {
      const timer = 120000
      this.timeoutLogoutMs = this.env.timeoutThreshold;
      this.timeoutWarningMs = this.timeoutLogoutMs - timer;
      this.inactivityTimer = setInterval(() => {
        let time = this.inactivitySubject.getValue();
        time += 1000;
         //console.log('Inactivity: ', time)
        this.inactivitySubject.next(time);
        this.checkForTimeout();
      }, 1000);
    }
  }

  /**
   * Check for session timeout, display appropriate alert if timing out.
   */
  public async checkForTimeout() {
    const authUser = this.authUser.getValue();
    if (authUser && authUser.userId) {
      const time = this.inactivitySubject.getValue();
      if (time === this.timeoutWarningMs) {
        if(this.alertExists.getValue()) {
            return;
        } else {
          const alert = await this.alertCtrl.create({
            header: "'Still there?' | translate",
            message: "'You will be signed out soon due to inactivity.' | translate",
            cssClass: 'wide-alert warning',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Sign out',
                handler: async (val) => {
                  await this.dismissAllModalsAndAlerts();
                  this.logout(false, true);
                }
              },
              {
                text: 'Stay signed in',
                cssClass: 'primary',
                handler: (val) => {
                  this.bumpInactivityTimer();
                  this.refreshSession();
                }
              },
            ]
          });
          await alert.present();
          this.alertExists.next(true);
        }
      } else if (time > this.timeoutLogoutMs) {
        await this.dismissAllModalsAndAlerts();
        this.logout(true, true);
      }
    }
  }

  /**
   * Dismisses all open alerts and modals
   */
  async dismissAllModalsAndAlerts(): Promise<boolean> {

    // Dismiss alerts
    for (let i = 0; i < 25; i++) {
      const alert = await this.alertCtrl.getTop();
      if (alert) {
        await alert.dismiss();
      } else {
        break;
      }
    }

    // Dismiss modals
    for (let i = 0; i < 25; i++) {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        break;
      }
    }

    return Promise.resolve(true);

  }

  /**
   * Bumps activity timer, preventing auto-timeout
   */
  public bumpInactivityTimer() {
    this.alertExists.next(false);
    this.inactivitySubject.next(0);
    // this.refreshSession();
  }

  /***
   * Logs user into application
   * @param userId User ID (username)
   * @param password  Password
   * @returns User Login Payload
   */
  login(userId, password, isPrivateDevice = false): Observable<LoginResponse> {
    const url = `${this.env.apiUrl}/auth/login?ts=${(new Date()).getTime()}`;
    const body: LoginRequest = {
      Username: userId,
      Password: password,
      IsPrivateDevice: isPrivateDevice
    };
    return this.http.post(url, body, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        // console.log('login/post:', data);
        this.handleLoginResponse(data.body as AuthStatus);
        return data.body;
      }),
    );
  }

  switchUser(targetUserId, stagedUserId): Observable<ProxySwitchReceipt> {
    const url = `${this.env.apiUrl}/users/0/switchEffectiveUser`;
    const body = {
      TargetUserID: targetUserId,
      TargetStagedUserID: stagedUserId,
    };
    return this.http.post(url, body, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  /**
   * Returns user state
   * @returns
   */
  getAuthStatus(): Observable<AuthStatus> {
    const url = `${this.env.apiUrl}/auth/status`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        this.handleLoginResponse(data.body);
        this.authStatusSubject.next(data.body);
        return data.body;
      }),
    );
  }

  /***
   * Process user response data, determining login status
   * @param data Login Response Data
   */
  async handleLoginResponse(authStatus: AuthStatus) {
    if (authStatus) {
      // const authStatus = await firstValueFrom(this.getAuthStatus());
      const userProfile = this.addSelfAsProfileOption(authStatus);
      this.authStatusSubject.next(authStatus);
      if(authStatus.LoggedIn === 1) {
        this.startTracking(authStatus);
      }
      const authUser: User = {};
      authUser.userId = authStatus.LoggedInUserID;
      authUser.authStatus = authStatus;
      authUser.viewingProfile = userProfile;
      authUser.firstName = authStatus.UserData.FirstName;
      authUser.lastName = authStatus.UserData.LastName;
      authUser.fullName = authStatus.UserData.FullName;
      authUser.hasProxyUsers = authStatus.ProxyFor.length > 1;
      authUser.role = this.setRole(authStatus);
      authUser.changePassword = authStatus.ChangePassword;
      this.setAuthUser(authUser);
      await this.reconnectProxiedProfile(authUser, authStatus);
      const rolesRes = await firstValueFrom(this.getUserRoles());
      authUser.roles = rolesRes.Roles;
      this.userState = {};
      this.userState.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
      this.userState.userId = authUser.userId;
      this.userState.environment = this.env;
      this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
      this.userState.authState = AuthState.LOGGED_IN;
      if (this.env.storeToken) {
        this.userState.authUser = authUser;
      }
      this.saveUserStateToStorage();
    }
    return;
  }

  async reconnectProxiedProfile(authUser: User, authStatus: AuthStatus): Promise<void> {
    const targetUserId = authStatus.EffectiveUserID;
    const targetUser = authStatus.ProxyFor.find(f => f.UserID === targetUserId);
    // console.log('reconnectProxiedProfile: EffectiveUserID: ', authStatus.EffectiveUserID);
    // console.log('reconnectProxiedProfile: LoggedInUserID: ', authStatus.LoggedInUserID);
    // console.log('reconnectProxiedProfile: authStatus: ', authStatus);
    // console.log('reconnectProxiedProfile: targetUser: ', targetUser);
    // console.log('reconnectProxiedProfile: authUser: ', authUser);
    // console.log('reconnectProxiedProfile: authStatus.LoggedInUserID: ', authStatus.LoggedInUserID);

    if (
      authUser.hasProxyUsers &&
      targetUser
    ) {
      try {
        // Switch Proxy User
        const res = await firstValueFrom(this.switchUser(targetUser.UserID, targetUser.StagedUserID));
        authUser.viewingProfile = targetUser;
        this.setAuthUser(authUser);
        Promise.resolve();
      } catch (err) {
        throw Promise.reject(err);
      }
    }
  }

  addSelfAsProfileOption(authStatus: AuthStatus): PersonalCommunityUser {
    const self: PersonalCommunityUser = {
      UserID: authStatus.LoggedInUserID,
      DisplayName: authStatus.UserData.FullName,
      DateCreated: null,
      DateModified: null,
      DisplayExpiration: null,
      Expiration: null,
      Expired: null,
      FirstName: authStatus.UserData.FirstName,
      LastModified: null,
      LastModifiedDisplay: null,
      LastName: authStatus.UserData.LastName,
      ProxyID: null,
      Relation: null,
      StagedUserID: authStatus.LoggedInUserID,
      TimeCreated: null,
      TimeCreatedDisplay: null
    };
    if (
      authStatus &&
      authStatus.ProxyFor &&
      authStatus.ProxyFor.find(pf => pf.UserID === authStatus.LoggedInUserID) === undefined) {
      authStatus.ProxyFor.unshift(self);
    }
    return self;
  }

  refreshSession(): Promise<string> {
    try {
      const url = `${this.env.apiUrl}/_ping`;
      return firstValueFrom(
        this.http.get(url, { observe: 'response', withCredentials: true, responseType: 'text' }).pipe(
          map((data: any) => {
            return data.body;
          })
        )
      );
    } catch (err) {
      console.error('refreshSession: error: ', err);
      return Promise.reject(err);
    }
  }

  /**
   * Get user roles
   * @returns
   */
  getUserRoles(): Observable<UserRolesResponse> {
    const url = `${this.env.apiUrl}/users/getUserRoles`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  setRole(authStatus: AuthStatus): 'USER' | 'PROXY' {
    if (authStatus.UserData.ProxyOnly) {
      return 'PROXY';
    } else {
      return 'USER';
    }
  }

  decodeJwt(token: string): any {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

      return JSON.parse(jsonPayload);
    } else {
      return null;
    }
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeJwt(token);
    // console.log('authService: isTokenExpired: ', decodedToken);

    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = new Date().getTime();
      // const expireMoment = moment(expirationTime);
      // console.log('authService: expireMoment: ', expireMoment.format('M/D/YYYY h:mm a'));

      return expirationTime <= currentTime;
    }

    // If the 'exp' claim is not present, consider the token as not expired
    return false;
  }

  getUriFromLocation(): string {
    // Get the current location
    const currentLocation = window.location;

    // Extract protocol, domain, and port
    const protocol = currentLocation.protocol;
    const domain = currentLocation.hostname;
    const port = currentLocation.port;
    const portSuffix = (port && port !== '80') ? ':' + port : '';

    // console.log("Protocol:", protocol);
    // console.log("Domain:", domain);
    // console.log("Port:", port);

    if (protocol === 'capacitor:' || this.deviceService.isNotBrowser()) {
      // Return web url for universal links
      return this.env.webUrl;
    } else {
      // Return localhost
      return protocol + '//' + domain + portSuffix;
    }
    // return protocol + '//' + domain + portSuffix;
  }

  async dismissAllModals(): Promise<boolean> {
    let topModal = await this.modalCtrl.getTop();
    while (topModal) {
      await this.modalCtrl.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
    return Promise.resolve(true);
  }

  /***
   * Logs user out
   * @param isExpired Determines if session expired
   * @param redirectToLogin Designates redirection to login page
   */
  async logout(isExpired = false, redirectToLogin = true) {
    const topAlert = await this.alertCtrl.getTop();
    if (topAlert) {
    await topAlert.dismiss();
    }
    sessionStorage.clear();
    localStorage.clear();
    await firstValueFrom(this.http.post(`${this.env.apiUrl}/auth/logout?ts=${(new Date()).getTime()}`, undefined));
    this.authUser.next(null);
    this.inactivitySubject.next(0);
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
    this.navService.navPages = [];

    if (isExpired) {
      this.userHasLoggedOutSignal.set(true);
      await this.dismissAllModalsAndAlerts();
      await this.dismissAllModals();
      this.alertExists.next(false);

      // Explicitly check for and dismiss any top alert, including inactivity alert
      const topAlert = await this.alertCtrl.getTop();
      if (topAlert) {
      await topAlert.dismiss();
      }

      // Ensure all alerts and modals are dismissed before showing expiration notification


      this.userState.authState = AuthState.EXPIRED;
      this.stopTracking(this.userState);
      // this.notifications.showAlert(
      //   'Signed out',
      //   'You were signed out due to inactivity or your session has expired.',
      //   'danger'
      // );
    } else {
     if(this.userState && this.userState.authState) {
      await this.dismissAllModalsAndAlerts();
      await this.dismissAllModals();
      this.userState.authState = AuthState.LOGGED_OUT;
      this.stopTracking(this.userState);
     }
    }

    this.storageService.removeData('userState');

    if (redirectToLogin) {
      await this.dismissAllModalsAndAlerts();
      await this.dismissAllModals();
      this.alertExists.next(false);

      // Explicitly check for and dismiss any top alert, including inactivity alert
      const topAlert = await this.alertCtrl.getTop();
      if (topAlert) {
      await topAlert.dismiss();
      }
      this.userLoggedIn = false;
      this.navService.navigateBack('login');
    }
  }


  ///Inactivity Functions

  startTracking(userStatus: AuthStatus) {
    if (userStatus.LoggedIn === 1 && !this.monitorUser) {
      this.monitorUser = true;
      this.userLoggedIn = true;
      this.resetTimer();
      this.addEventListeners();
    }
  }

  private addEventListeners() {
    this.userInteractionEvents.forEach((event) => {
      document.addEventListener(event, () => this.resetTimer());
    });
  }

  private removeEventListeners() {
    this.userInteractionEvents.forEach((event) => {
      document.addEventListener(event, () => this.resetTimer());
    });
  }

  private resetTimer() {

    if (!this.userLoggedIn || this.alertExists.getValue()) return;

    clearTimeout(this.inactivityTimer);
    clearTimeout(this.warningTimer);

    // Show alert after 4.5 minutes
    this.warningTimer = setTimeout(
      () => this.showInactivityAlert(),
      this.warningTime
    );

    // Auto logout after 5 minutes
    this.inactivityTimer = setTimeout(
      async () => {
        this.logout(true, true);
      },this.logoutTime);
  }

  private async showInactivityAlert() {
    console.log('this.userLoggedIn', this.userLoggedIn);

    if (!this.userLoggedIn || this.alertExists.getValue()) return;
    this.alertExists.next(true);
    const alert = await this.alertCtrl.create({
      header: "Still there?",
      message: "You will be signed out soon due to inactivity.",
      cssClass: 'wide-alert warning',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Sign out',
          handler: async (val) => {
            this.logout(false, true);
          },
        },
        {
          text: 'Stay signed in',
          cssClass: 'primary',
          handler: (val) => {
            this.resetTimer();
            this.refreshSession();
            this.alertExists.next(false);
          },
        },
      ],
    });
    await alert.present();
  }

  async stopTracking(userState: UserState) {
    if (userState.authState === 2) {
      this.monitorUser = false;
      this.userLoggedIn = false;
      this.clearTimers();
      this.removeEventListeners();
    } else if (userState.authState === 1) {
      this.monitorUser = false;
      this.userLoggedIn = false;
      this.clearTimers();
      this.removeEventListeners();
    }
  }

  private clearTimers() {
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.warningTimer);
  }

  checkForLightUser(roles: string[]): boolean {
    let isLiteUser = false;
    if(roles && roles.length > 0) {
      isLiteUser = roles.some(role => role === 'LITEUSER');
    }
    return isLiteUser;
  }
}
