import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { Tile } from 'src/app/model/Tile';
import { StateService } from 'src/app/service/state.service';
import { AccountSummaryService } from 'src/app/service/account-summary.service';
import { Router } from '@angular/router';
import { RolesService } from 'src/app/service/roles.service';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})

export class ResourcesComponent implements OnInit {


  @Input() selectedUser: UserIdentifier;
  environment: any;
  constructor(
    private router: Router,
    private _rolesService: RolesService
  ) { }

  tiles: Tile[] = [];

  readonly helpTiles: Tile[] = [
    { image: 'Locations', text: 'Browse locations', link: 'https://www.baystatehealth.org/locations', isExternal: true },
    { image: 'MedicalServices', text: 'Medical services', link: 'https://www.baystatehealth.org/services', isExternal: true },
    { image: 'News', text: 'News and more', link: 'https://www.baystatehealth.org/news', isExternal: true },
    { image: 'GiftShop', text: 'Gift shop and online store', link: 'https://www.baystatehealth.org/patients/gift-shops', isExternal: true },
    { image: 'VisitingHours', text: 'Visiting hours', link: 'https://www.baystatehealth.org/patients/visiting', isExternal: true },
    { image: 'BhWayToGo', text: 'Find my way (WayToGo)', link: 'https://atobe.app/photo-route?buildingId=26&MultiBuilding=26%3B16%3B27%3B15&routeLang=en&channel=GATEWAY', isExternal: true },
  ];

  readonly connectTiles: Tile[] = [
    { image: 'AppleHealth', text: 'Connect Mobile Apps to your Record', isExternal: false, link: '/authenticated/resources/apple-health-setup', iconClass: 'apple-health' },
    { image: 'patientslikeme', text: 'PatientsLikeMe', link: 'https://www.patientslikeme.com/', isExternal: true },
    { image: 'ompractice', text: 'OM Practice', link: 'https://www.ompractice.com/', isExternal: true },

  ];

  ngOnInit() {
    // show a different set of tiles depending if it's a proxy only user
    this._rolesService.isProxyOnlyUser.subscribe(isProxyOnlyUser => {
      if (isProxyOnlyUser) {
        this.tiles = [
          // { image: 'SymptomChecker', text: 'Symptom checker', link: '/authenticated/resources/symptom-checker'},
          //{ image: 'SymptomChecker', text: 'Check symptoms <br />and find care', link: 'https://medchatapp.com/widget/d7ITdpzdYEab6GX7uUv3Yw/chat' },
          // { image: 'AskAQuestion', text: 'Ask a librarian', link: '/authenticated/resources/ask-a-librarian' },
          { image: 'MobileApps', text: 'Explore our apps', link: 'https://appstore.apps.baystatehealth.org' },
          { image: 'Dictionary', text: 'Health dictionary', link: '/authenticated/resources/health-dictionary' },
          { image: 'Providers', text: 'Find a provider', link: 'http://baystatehealth.org/providers?search?filter=networks.network%3AEmployed+Network&sort=networks%2Crelevance&_ga=2.221954904.1725562559.1573167976-1211091091.1570105436' },
          { image: 'PayABill', text: 'Pay a bill', link: environment.PAY_URL },
          { image: 'RequestRecords', text: 'Records request', link: '/authenticated/resources/forms-list/PatientPortalROI' },
          { image: 'CovidSymptoms', text: 'COVID-19 Symptoms', link: 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html' },
          { image: 'LabXrayPatient-icon', text: 'Get in line for  <br />lab or radiology work', link: '/authenticated/patient-checkin/check-in-now' },
          { image: 'BhWayToGo', text: 'Find my way (WayToGo)', link: 'https://atobe.app/photo-route?buildingId=26&MultiBuilding=26%3B16%3B27%3B15&routeLang=en&channel=GATEWAY', isExternal: true }
          // { image: 'LabWork-icon', text: 'Get in line for lab work', link: '/patient-checkin/check-in-now' },
        ];
      } else { this.tiles = [
        // { image: 'SymptomChecker', text: 'Symptom checker', link: '/authenticated/resources/symptom-checker'},
        //{ image: 'SymptomChecker', text: 'Check symptoms <br />and find care', link: 'https://medchatapp.com/widget/d7ITdpzdYEab6GX7uUv3Yw/chat' },
        // { image: 'AskAQuestion', text: 'Ask a librarian', link: '/authenticated/resources/ask-a-librarian' },
        { image: 'PatientForms', text: 'Patient forms', link: '/authenticated/resources/forms-list' },
        { image: 'MobileApps', text: 'Explore our apps', link: 'https://appstore.apps.baystatehealth.org' },
        { image: 'Dictionary', text: 'Health dictionary', link: '/authenticated/resources/health-dictionary' },
        { image: 'Providers', text: 'Find a provider', link: 'http://providers.baystatehealth.org/search?filter=networks.network%3AEmployed+Network&sort=networks%2Crelevance&_ga=2.221954904.1725562559.1573167976-1211091091.1570105436' },
        { image: 'PayABill', text: 'Pay a bill', link: environment.PAY_URL},
        { image: 'RequestRecords', text:"Records request", link: '/authenticated/resources/forms-list/PatientPortalROI' },
        // { image: 'CovidSymptoms', text: 'COVID-19 Symptoms', link: 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html' },
        { image: 'LabXrayPatient-icon', text: 'Get in line for  <br />lab or radiology work', link: '/authenticated/patient-checkin/check-in-now' },
        { image: 'BhWayToGo', text: 'Find my way (WayToGo)', link: 'https://atobe.app/photo-route?buildingId=26&MultiBuilding=26%3B16%3B27%3B15&routeLang=en&channel=GATEWAY', isExternal: true }
        // { image: 'LabWork-icon', text: 'Get in line for lab work', link: '/patient-checkin/check-in-now' },
      ];
      }
    });

  }

  openLink(link: Tile) {
    if (link.isExternal) {
      // window.open(link.link, '_blank');
      window.location.href = link.link;
    } else {
      this.router.navigate([link.link]);
    }
  }

}
