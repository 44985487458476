import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[dobMask]'
})
export class DobMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any): void {
    // Get input value and replace non-numeric characters
    let value: string = this.el.nativeElement.value.replace(/\D/g, '');

    if (value.length > 8) {
      value = value.slice(0, 8);
    }



    // Apply the mask (MM/DD/YYYY)
    if (value.length >= 3 && value.length <= 4) {
      value = value.replace(/^(\d{2})(\d{1,2})/, '$1/$2');
    } else if (value.length >= 5 && value.length <= 8) {
      value = value.replace(/^(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3');
    }

    if (value.length > 10) {
      value = value.substring(0, 10);

      // Handle the year part
    const parts = value.split('/');
    if (parts.length === 3 && parts[2].length === 2) {
      const year = parseInt(parts[2], 10);
      if (year >= 0 && year <= 99) {
        if (year < 50) {
          parts[2] = `20${('0' + year).slice(-2)}`;
        } else {
          parts[2] = `19${('0' + year).slice(-2)}`;
        }
        value = `${parts[0]}/${parts[1]}/${parts[2]}`;
      }
    }
    }



    // Update the value in the input field
    this.el.nativeElement.value = value;
    this.el.nativeElement.maxLength = 10;
  }

  @HostListener('blur', ['$event']) onBlur(event: any): void {
    let value: string = this.el.nativeElement.value;

    if (value.length === 5) {
      value = value.replace(/^(\d{2})\/(\d{1})$/, '$1/0$2');
    }

    // Ensure the value has the full format (e.g., MM/DD/YYYY)
    if (value.length === 7) {
      value = value.replace(/^(\d{2})\/(\d{2})\/(\d{1,4})$/, '$1/$2/0$3');
    }

    this.el.nativeElement.value = value;
  }

}
