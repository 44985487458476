import { Routes } from '@angular/router';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/_core/help/help.page').then( m => m.HelpPage)
  },
  {
    path: 'share',
    loadComponent: () => import('./pages/_core/share/share.page').then( m => m.SharePage)
  },
  {
    path: 'check-for-updates',
    loadComponent: () => import('./pages/_core/check-for-updates/check-for-updates.page').then( m => m.CheckForUpdatesPage)
  },
  {
    path: 'help-forgot-pwd',
    loadComponent: () => import('./pages/_core/help-forgot-pwd/help-forgot-pwd.page').then( m => m.HelpForgotPwdPage)
  },
  {
    path: 'feedback-modal',
    loadComponent: () => import('./pages/_core/feedback-modal/feedback-modal.page').then( m => m.FeedbackModalPage)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/account/my-account/my-account.page').then( m => m.MyAccountPage)
  },
  {
    path: 'my-account/view-history',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/account/view-history/view-history.page').then( m => m.ViewHistoryPage)
  },
  {
    path: 'my-account/delete-account',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/account/delete-account/delete-account.page').then( m => m.DeleteAccountPage)
  },
  {
    path: 'language-modal',
    loadComponent: () => import('./pages/_core/language-modal/language-modal.page').then( m => m.LanguageModalPage)
  },
  {
    path: 'language-popover',
    loadComponent: () => import('./pages/_core/language-popover/language-popover.page').then( m => m.LanguagePopoverPage)
  },
  {
    path: 'language-translator',
    loadComponent: () => import('./pages/_core/language-translator/language-translator.page').then( m => m.LanguageTranslatorPage)
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'messages',
    loadComponent: () => import('./pages/messages/messages.page').then( m => m.MessagesPage)
  },
  {
    path: 'health-record',
    loadComponent: () => import('./pages/health-record/health-record.page').then( m => m.HealthRecordPage)
  },
  {
    path: 'resources',
    loadComponent: () => import('./pages/resources/resources.page').then( m => m.ResourcesPage)
  },
  {
    path: 'profile-select',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/profile-select/profile-select.page').then( m => m.ProfileSelectPage)
  },
  {
    path: 'signup',
    loadComponent: () => import('./pages/account/account-signup/account-signup.page').then( m => m.AccountSignupPage)
  },
  {
    path: 'signup/:action',
    loadComponent: () => import('./pages/account/account-signup/account-signup.page').then( m => m.AccountSignupPage)
  },
  {
    path: 'activate-account',
    loadComponent: () => import('./pages/account/account-signup/account-signup.page').then( m => m.AccountSignupPage)
  },
  {
    path: 'terms-of-use-modal',
    loadComponent: () => import('./pages/terms-of-use-modal/terms-of-use-modal.page').then( m => m.TermsOfUseModalPage)
  },
  {
    path: 'modal',
    loadComponent: () => import('./pages/modal/modal.page').then( m => m.ModalPage)
  },
  {
    path: 'medical-urgent-modal',
    loadComponent: () => import('./pages/messages/new-message/medical-urgent-modal/medical-urgent-modal.page').then( m => m.MedicalUrgentModalPage)
  },
  {
    path: 'file-viewer',
    loadComponent: () => import('./pages/messages/file-viewer/file-viewer.page').then( m => m.FileViewerPage)
  },
  {
    path: 'test-results',
    loadComponent: () => import('./pages/health-record/test-results/test-results.page').then( m => m.TestResultsPage)
  },
  {
    path: 'lab-result',
    loadComponent: () => import('./pages/health-record/test-results/lab-result/lab-result.page').then( m => m.LabResultPage)
  },
  {
    path: 'rad-result',
    loadComponent: () => import('./pages/health-record/test-results/rad-result/rad-result.page').then( m => m.RadResultPage)
  },
  {
    path: 'lab-result-history',
    loadComponent: () => import('./pages/health-record/test-results/lab-result-history/lab-result-history.page').then( m => m.LabResultHistoryPage)
  },
  {
    path: 'visits',
    loadComponent: () => import('./pages/health-record/visits/visits.page').then( m => m.VisitsPage)
  },
  {
    path: 'appointment',
    loadComponent: () => import('./pages/health-record/visits/appointment/appointment.page').then( m => m.AppointmentPage)
  },
  {
    path: 'encounter',
    loadComponent: () => import('./pages/health-record/visits/encounter/encounter.page').then( m => m.EncounterPage)
  },
  {
    path: 'reports',
    loadComponent: () => import('./pages/health-record/reports/reports.page').then( m => m.ReportsPage)
  },
  {
    path: 'document',
    loadComponent: () => import('./pages/health-record/reports/document/document.page').then( m => m.DocumentPage)
  },
  {
    path: 'medicines',
    loadComponent: () => import('./pages/health-record/medicines/medicines.page').then( m => m.MedicinesPage)
  },
  {
    path: 'medicine-detail',
    loadComponent: () => import('./pages/health-record/medicines/medicine-detail/medicine-detail.page').then( m => m.MedicineDetailPage)
  },
  {
    path: 'health-stats',
    loadComponent: () => import('./pages/health-record/health-stats/health-stats.page').then( m => m.HealthStatsPage)
  },
  {
    path: 'health-stats-detail',
    loadComponent: () => import('./pages/health-record/health-stats/health-stats-detail/health-stats-detail.page').then( m => m.HealthStatsDetailPage)
  },
  {
    path: 'conditions',
    loadComponent: () => import('./pages/health-record/conditions/conditions.page').then( m => m.ConditionsPage)
  },
  {
    path: 'allergies',
    loadComponent: () => import('./pages/health-record/allergies/allergies.page').then( m => m.AllergiesPage)
  },
  {
    path: 'vaccinations',
    loadComponent: () => import('./pages/health-record/vaccinations/vaccinations.page').then( m => m.VaccinationsPage)
  },
  {
    path: 'share-health-record',
    loadComponent: () => import('./pages/health-record/share-health-record/share-health-record.page').then( m => m.ShareHealthRecordPage)
  },
  {
    path: 'iframe-modal',
    loadComponent: () => import('./pages/iframe-modal/iframe-modal.page').then( m => m.IframeModalPage)
  },
  {
    path: 'patient-forms',
    loadComponent: () => import('./pages/resources/patient-forms/patient-forms.page').then( m => m.PatientFormsPage)
  },
  {
    path: 'new-patient-form',
    loadComponent: () => import('./pages/resources/patient-forms/new-patient-form/new-patient-form.page').then( m => m.NewPatientFormPage)
  },
  {
    path: 'connect-mobile-apps',
    loadComponent: () => import('./pages/resources/connect-mobile-apps/connect-mobile-apps.page').then( m => m.ConnectMobileAppsPage)
  },
  {
    path: 'health-dictionary',
    loadComponent: () => import('./pages/resources/health-dictionary/health-dictionary.page').then( m => m.HealthDictionaryPage)
  },
  {
    path: 'labcorp-redirect',
    loadComponent: () => import('./pages/resources/get-in-line/labcorp-redirect/labcorp-redirect.page').then( m => m.LabcorpRedirectPage)
  },
  {
    path: 'change-password-modal',
    loadComponent: () => import('./pages/account/change-password-modal/change-password-modal.page').then( m => m.ChangePasswordModalPage)
  },
  {
    path: 'change-email-modal',
    loadComponent: () => import('./pages/account/change-email-modal/change-email-modal.page').then( m => m.ChangeEmailModalPage)
  },
  {
    path: 'change-phone-modal',
    loadComponent: () => import('./pages/account/change-phone-modal/change-phone-modal.page').then( m => m.ChangePhoneModalPage)
  },
  {
    path: 'view-history',
    loadComponent: () => import('./pages/account/view-history/view-history.page').then( m => m.ViewHistoryPage)
  },
  {
    path: 'delete-account',
    loadComponent: () => import('./pages/account/delete-account/delete-account.page').then( m => m.DeleteAccountPage)
  },
  {
    path: 'forgot-credentials',
    loadComponent: () => import('./pages/account/forgot-credentials/forgot-credentials.page').then( m => m.ForgotCredentialsPage)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./pages/account/reset-password/reset-password.page').then( m => m.ResetPasswordPage)
  },
  {
    path: 'expired-password',
    loadComponent: () => import('./pages/account/reset-password/reset-password.page').then( m => m.ResetPasswordPage)
  },
  {
    path: 'about-us',
    loadComponent: () => import('./pages/about-us/about-us.page').then( m => m.AboutUsPage)
  },
  {
    path: 'remove-proxy-access-modal',
    loadComponent: () => import('./pages/account/remove-proxy-access-modal/remove-proxy-access-modal.page').then( m => m.RemoveProxyAccessModalPage)
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./pages/contact-us/contact-us.page').then( m => m.ContactUsPage)
  },
  {
    path: 'landing',
    loadComponent: () => import('./pages/landing/landing.page').then( m => m.LandingPage)
  },
  {
    path: 'change-preferred-name',
    loadComponent: () => import('./pages/account/change-preferred-name/change-preferred-name.page').then( m => m.ChangePreferredNamePage)
  },
  {
    path: 'pdf-viewer-modal',
    loadComponent: () => import('./pages/resources/pdf-viewer-modal/pdf-viewer-modal.page').then( m => m.PdfViewerModalPage)
  },
];
