import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ModalService } from "src/app/service/modal.service";
import { ModalType } from "src/app/model/ModalTypeEnum";
import { PersonalInformationService } from "./personal-information.service";
import { Printer, PrintOptions } from "@ionic-native/printer/ngx";
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Injectable({
  providedIn: "root",
})
export class PrintService {
  constructor(
    private _personalInformationService: PersonalInformationService,
    private _modalService: ModalService,
    private _nativePrinter: Printer,
    private _platform: Platform,
   // private iab: InAppBrowser
  ) {}

  /**
   * Opens html string in a new tab and handles printing.
   * This logic has been carefully tested on multiple browsers and devices.
   * PLEASE DO NOT MODIFY WITHOUT TESTING ON ALL BROWSERS + ANDROID AND IOS.
   * For instance "WindowPrt.close()"" breaks Safari.
   */
  printHtmlString(htmlString: string, isMobile?: boolean) {
    const isMobileApp = this._platform.is("cordova") || isMobile;
    const isMobileBrowser = /Mobi/.test(navigator.userAgent);
    this._personalInformationService.demographics().then((demographics) => {
      const printPageDialog = `
        <div class="trigger-print-dialog screen-only">
          If print dialog does not open automatically <button onclick = "window.print()"> click here </button>
        </div>
        <div class="trigger-print-dialog screen-only" >
          After printing, close this page to return to the MyBaystate Patient Portal.
        </div>`;

      const printHeader = `
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <style>
        html {
          font-family: 'Open Sans', Roboto, Helvetica, sans-serif;
        }

        .patient-name {
          font-weight: 600;
          text-transform: none;
          font-size: 20px;
          margin-top: 0;
          padding-bottom: 8px;
        }

        h2 {
          margin-bottom: 8px;
        }

        td.label {
          width: 150px;
          font-weight: 600;
        }

        .bh-ac-logo {
          height: 40px;
          width: 190px;
        }

        ion-item {
          display: block;
          margin-bottom: 8px;
          margin-left: 8px;
        }

        ion-row {
          display: flex;
        }

        ion-col {
          min-width: 150px;
        }

        ion-card:first-of-type {
          margin-top: 24px;
        }

        ion-card {
          display: block;
          border-radius: 16px;
          padding: 16px;
          margin: 8px 0;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }

        ion-button,
        ion-card-subtitle {
          display: none;
        }

        ion-card-title {
          display: block;
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 17px;
        }

        @media screen {
          body > *:not(.trigger-print-dialog) {
            display: none;
          }
        }

        @media print {
          .screen-only {
            display: none;
          }
          .no-print{
            display: none;
          }
        }
      </style>
      ${!isMobileApp || isMobileBrowser ? printPageDialog : ""}
      <div id="html2Pdf">
      <table style="width: 100%">
        <tr>
          <td>
            <table>
              <tr>
                <td class="patient-name" colspan="2">${
                  demographics.Patient.Name || ""
                }</td>
              </tr>
              <tr>
                <td class="label">Date of Birth: </td>
                <td>${demographics.Patient.DateOfBirthDisplay || ""}</td>
              </tr>
              <tr>
                <td class="label">Age: </td>
                <td>${demographics.Patient.PatientAge || ""}</td>
              </tr>
              <tr>
                <td class="label">Gender: </td>
                <td>${demographics.Patient.Gender || ""}</td>
              </tr>
            </table>
          </td>
          <td style="text-align: right">
            <img _ngcontent-dhq-c9="" class="bh-ac-logo" src="/assets/logos/bh-logo-ac-2l.png">
          </td>
        </tr>
      </table>
      <hr>
      </div>
    `;
      const windowContent = printHeader + htmlString;
      const WindowPrt = window.open();
      //const WindowSystem = window.open()
      if (!isMobileApp) {
        //const isMobileBrowser = /Mobi/.test(navigator.userAgent);
        // Check user is viewing site on a mobile browser, and if pop up is blocked.
        if (
          isMobileBrowser &&
          (!WindowPrt ||
            WindowPrt.closed ||
            typeof WindowPrt.closed == undefined)
        ) {
          this._modalService.open(
            "Print Window Blocked",
            "If you would like to print on your mobile device, please disable your browser's popup blocker.",
            ModalType.BASIC
          );
        } else {
          WindowPrt.document.write(windowContent);
          setTimeout(() => {
            WindowPrt.focus();
            WindowPrt.print();
          }, 550);
        }
      } else {
        if(isMobileBrowser) {

          // this._nativePrinter.isAvailable().then(onSuccess => {
          //   this._nativePrinter.print(htmlString).then(onPrintSuccess => {
          //     console.log('Print successful!');
          //   }).catch(err => {
          //     console.log('Print error:', err);
          //   });
          // }).catch(err => {
          //   console.log('Printer not available:', err);
          // });
          //this.iab.open( windowContent, '_system');
          window.open(windowContent, '_system');
           // WindowPrt.document.write(windowContent);
           //WindowPrt.focus();
           // WindowPrt.print();
        } else {
          window.open(windowContent, '_system');

          // this._nativePrinter.isAvailable().then(onSuccess => {
          //   this._nativePrinter.print(htmlString).then(onPrintSuccess => {
          //     console.log('Print successful!');
          //   }).catch(err => {
          //     console.log('Print error:', err);
          //   });
          // }).catch(err => {
          //   console.log('Printer not available:', err);
          // });

          //const mobileWindow = window.document.write(windowContent)
          //this.iab.open( windowContent, '_system');
          //WindowPrt.focus();
          //WindowPrt.print();
          // setTimeout(() => {
          //   this._nativePrinter.print(htmlString);
          // }, 550);
        }
      }
    });
  }
}
