import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DateHandlerService } from '../date-handler/date-handler.service';
import { Allergy } from 'src/app/models/allergy';
import { Appointment } from 'src/app/models/appointment';
import { Document } from 'src/app/models/document';
import moment from 'moment';
import { SocialHistoriesResponse } from 'src/app/models/social-histories-response';
import { Encounter } from 'src/app/models/encounter';
import { AppointmentsResponse } from 'src/app/models/appointments-response';
import { EncountersResponse } from 'src/app/models/encounters-response';
import { DocumentResponse } from 'src/app/models/document-response';
import { VitalsResponse } from 'src/app/models/vitals-response';
import { Medication } from 'src/app/models/medication';
import { MedicationsResponse } from 'src/app/models/medications-response';
import { VitalsObservation } from 'src/app/models/vitals-observation';
import { VitalsObservationHistoryResponse } from 'src/app/models/vitals-observation-history-response';
import StatCodes from 'src/assets/json/vitalCodes.json';
import { ProblemResponse } from 'src/app/models/problem-response';
import { AllergyResponse } from 'src/app/models/allergy-response';
import { ImmunizationResponse } from 'src/app/models/immunization-response';

@Injectable({
  providedIn: 'root'
})
export class HealthRecordService {
  env = environment;
  upcomingAppts: BehaviorSubject<Appointment[]> = new BehaviorSubject([]);
  newEducation: BehaviorSubject<Document[]> = new BehaviorSubject([]);
  newNotes: BehaviorSubject<Document[]> = new BehaviorSubject([]);
  selectedAppointment: Appointment;
  selectedEncounter: Encounter;
  selectedDocument: Document;
  selectedMedication: Medication;
  selectedVital: VitalsObservation;

  constructor(
    private http: HttpClient,
    private dateHandler: DateHandlerService
  ) { }

  getFacilitiesPhoneNumber(): Observable<any> {
    const url = `${this.env.apiUrl}/customapi/facilityList`;
    return this.http.get(url, { observe: 'response', withCredentials: false }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getAllergies(): Observable<AllergyResponse> {
    const url = `${this.env.apiUrl}/ehr/category/allergies`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        if (data && data.body && data.body.Allergies) {
          const allergies: Allergy[] = data.body.Allergies;
          for (const a of allergies) {
            if (a.EnteredTime) { a.EnteredTime = this.dateHandler.parseDateString(a.EnteredTime) }
          }
          data.body.Allergies = allergies;
        }
        return data.body;
      }),
    );
  }

  async getUpcomingAppointments(): Promise<Appointment[]> {
    try {
      const res = await firstValueFrom(this.getAppointments());
      let upcoming = [];
      if (res.error) {
        console.log('getUpcomingAppointments', res.error);
      } else {
        upcoming = res.Appointments.filter(a => {
          const apptMoment = moment(a.FromTime, 'YYYY-MM-DD HH:mm:ss');
          return apptMoment > moment();
        });
        this.upcomingAppts.next(upcoming);
      }
      return Promise.resolve(upcoming);
    } catch (err) {
      console.error('getUpcomingAppointments: Error getting upcoming appts', err);
      return Promise.reject(err);
    }
  }

  getAppointments(futureOnly = true): Observable<AppointmentsResponse> {
    const queryString = futureOnly ? `?ts=${Date.now()}` : '';
    const url = `${this.env.apiUrl}/ehr/category/appointments` + queryString;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        if (data && data.body && data.body.Appointments) {
          const appointments: Appointment[] = data.body.Appointments;
          for (const a of appointments) {
            a.FromTime = this.dateHandler.parseDateString(a.FromTime)
            // console.log('getAppointments', a.FromTime, a.EnteredTime);
          }
          appointments.sort((a, b) => {
            return this.dateHandler.sortByMoment(a.Timestamp, b.Timestamp, 'YYYY-MM-DD HH:mm:ss', 'newest');
          });
          data.body.Appointments = appointments;
        } else if (data.error) {
          throw Error(data.error);
        }
        return data.body;
      }),
    );
  }

  getConcerns(): Observable<any> {
    const url = `${this.env.apiUrl}/ehr/category/healthConcerns`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  async getNewDocuments(): Promise<any> {
    try {
      const nowMoment = moment();
      const res = await firstValueFrom(this.getDocuments());
      //let testResults = [];console.log('res documents', res );
      if(res && res.Documents && res.Documents.length > 0) {
        const newEducation = res.Documents.filter(a => {
          d => d.DocumentName.trim().toLowerCase().indexOf('krames patient education') > -1 &&
            nowMoment.diff(moment(d.Timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') < 30
        });
        const newNotes = res.Documents.filter(a => {
          d => d.DocumentName.trim().toLowerCase().indexOf('krames patient education') === -1 &&
            nowMoment.diff(moment(d.Timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') < 14
        });
        this.newEducation.next(newEducation);
        this.newNotes.next(newNotes);
        Promise.resolve({ newEducation, newNotes });
      }
    } catch (err) {
      console.error('getNewDocuments: Error getting upcoming documents', err);
      Promise.reject(err);
    }
  }


  getDocuments(): Observable<DocumentResponse> {
    const url = `${this.env.apiUrl}/ehr/category/documents?ts=${Date.now()}`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        if (data && data.body && data.body.Documents) {
          const documents: Document[] = data.body.Documents;
          data.body.Documents = documents;
        }
        return data.body;
      }),
    );
  }

  getGoals(): Observable<any> {
    const url = `${this.env.apiUrl}/ehr/category/goals`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getProblems(): Observable<ProblemResponse> {
    const url = `${this.env.apiUrl}/ehr/category/problems`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getVitals(): Observable<VitalsResponse> {
    const url = `${this.env.apiUrl}/ehr/category/vitals`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getVitalsChart(observations: string[]): Observable<any> {
    const url = `${this.env.apiUrl}/ehr/vitalsChart`;
    const body = {
      Biomarkers: observations
    }
    return this.http.post(url, body, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getVitalsHistory(selectedObs: string[]): Observable<VitalsObservationHistoryResponse> {
    const url = `${this.env.apiUrl}/ehr/vitals?obs=${selectedObs}`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getVitalValueByCode(code: string, vitals: any[], returnUnit: boolean) {
    let vital = this.getVitalByCode(code, vitals);
    if (vital) {
      if (returnUnit) {
        return vital.ObservationValue + ' ' + vital.ObservationUnits;
      } else {
        return vital.ObservationValue;
      }
    }
    return '';
  }

  getVitalByCode(code: string, vitals: any[]) {
    if (vitals != null) {
      return vitals.find(h => h.Code === code);
    } else {
      return [];
    }
  }

  isHeroVital(code: string): boolean {
    let isHero = false;
    isHero = (
      code === StatCodes.VitalCodes.BMI ||
      code === StatCodes.VitalCodes.Height ||
      code === StatCodes.VitalCodes.Oximetry ||
      code === StatCodes.VitalCodes.Pulse ||
      code === StatCodes.VitalCodes.RespiratoryRate ||
      code === StatCodes.VitalCodes.Temperature ||
      code === StatCodes.VitalCodes.Weight
    )
    return isHero;
  }

  isDashboardVital(code: string): boolean {
    let isHero = false;
    isHero = (
      code === StatCodes.VitalCodes.BMI ||
      code === StatCodes.VitalCodes.Height ||
      code === StatCodes.VitalCodes.Oximetry ||
      code === StatCodes.VitalCodes.Pulse ||
      code === StatCodes.VitalCodes.RespiratoryRate ||
      code === StatCodes.VitalCodes.Weight
    )
    return isHero;
  }

  getBloodPressure(vitals: VitalsObservation[]): VitalsObservation {
    const diastolic = vitals.find(v => v.Code === StatCodes.VitalCodes.DiastolicBP);
    const systolic = vitals.find(v => v.Code === StatCodes.VitalCodes.SystolicBP);
    if (diastolic && systolic) {
      const bp = Object.assign({}, diastolic);
      bp.ObservationsTranslated = [];
      bp.Code = 'bp';
      bp.Observation = 'Blood Pressure';
      bp.calcValue = 'Not available';
      bp.calcValue = systolic.ObservationValue + '/' + diastolic.ObservationValue;
      bp.ObservationsTranslated.push(...[systolic.ObservationTranslated, diastolic.ObservationTranslated]);
      bp.ObservationType = 'Systolic';
      bp.ObservationType2 = 'Diastolic';
      bp.ObservationValue = systolic.ObservationValue;
      bp.ObservationValue2 = diastolic.ObservationValue;
      bp.PulsePressure = Math.floor(systolic.ObservationValue - diastolic.ObservationValue);
      return bp;
    } else {
      return undefined;
    }
  }

  getRadResults(): Observable<any> {
    const url = `${this.env.apiUrl}/ehr/category/radOrders`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getMedicines(): Observable<MedicationsResponse> {
    const url = `${this.env.apiUrl}/ehr/medications?which=all`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  isMedicineHistorical(m: Medication) {
    return (
      (m.ToTime !== '' && new Date() > this.dateHandler.parseDateString(m.ToTime)) ||
      m.RefillsRemaining === '' ?
      true : false)
  }

  getSocialHabits(): Observable<SocialHistoriesResponse> {
    const url = `${this.env.apiUrl}/ehr/category/socialHx`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        // return data.body;
        data.body.SocialHistories.sort((a, b) => {
          let aDateToSort;
          let bDateToSort;

          if (a.UpdatedTime !== '') {
            aDateToSort = a.UpdatedTime;
          } else if (a.EnteredTime !== '') {
            aDateToSort = a.EnteredTime;
          }

          if (b.UpdatedTime !== '') {
            bDateToSort = b.UpdatedTime;
          } else if (b.EnteredTime !== '') {
            bDateToSort = b.EnteredTime;
          }

          return (Date.parse(bDateToSort) - Date.parse(aDateToSort));
        });
        return data.body;
      }),
    );
  }

  getImmunizations(): Observable<ImmunizationResponse> {
    const url = `${this.env.apiUrl}/ehr/category/immunizations`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        return data.body;
      }),
    );
  }

  getEncounters(): Observable<EncountersResponse> {
    const url = `${this.env.apiUrl}/ehr/category/encounters`;
    return this.http.get(url, { observe: 'response', withCredentials: true }).pipe(
      map((data: any) => {
        const encounters: Encounter[] = data.body.Encounters;
        encounters.sort((a, b) => {
          return this.dateHandler.sortByMoment(a.Timestamp, b.Timestamp, 'YYYY-MM-DD HH:mm:ss', 'newest');
        });
        data.body.Encounters = encounters;
        return data.body;
      }),
    );
  }

}
