import { NgModule } from '@angular/core';
import { addIcons } from 'ionicons';
import {
  // Common interface/navigation icons
  home,
  person,
  settings,
  search,
  notifications,
  menuOutline,
  closeOutline,
  add,
  remove,
  chevronBack,
  chevronForward,
  chevronDown,
  chevronUp,
  arrowBack,
  arrowForward,
  arrowDown,
  arrowUp,

  // Status/notification icons
  checkmarkCircleOutline,
  alertCircleOutline,
  informationCircleOutline,
  warningOutline,
  helpCircleOutline,


  // Action icons
  createOutline,
  trashOutline,
  saveOutline,
  refreshOutline,
  downloadOutline,
  shareOutline,
  documentOutline,

  // Communication icons
  mailOutline,
  callOutline,
  chatbubbleOutline,

  // Other useful icons
  timeOutline,
  calendarOutline,
  locationOutline,
  cameraOutline,
  imageOutline,
  filterOutline,
  flagOutline,
  folderOutline,
  peopleOutline,
  pricetagOutline,
  reloadOutline,
  lockClosedOutline,
  lockOpenOutline,
  printOutline,

  // Tab icons (from the existing code)
  triangle,
  ellipse,
  square,
  arrowUpOutline,
  arrowBackOutline,
  arrowForwardOutline,
  arrowDownOutline,
  library,
  heart,
  mail,
  chevronDownOutline,
  personOutline,
  personCircleOutline,
  logInOutline,
  logOutOutline,
  moonOutline,
  globeOutline,
  bulbOutline,
  sunnyOutline,
  volumeHighOutline,
  volumeLowOutline,
  volumeMediumOutline,
  volumeMuteOutline,
  volumeOffOutline,
  chevronForwardOutline,
  chevronBackOutline,
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
  statsChartOutline,
  statsChart,
  pulseOutline,
  pulse,
  ellipseOutline,
  fileTrayOutline,
  sendOutline,
  folderOpenOutline,
  addCircleOutline,
  archiveOutline,
  arrowDownCircleOutline,
  medicalOutline,
  chatboxEllipsesOutline,
  starOutline,
  earthOutline,
  eye,
  folderOpen,
} from 'ionicons/icons';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class IonIconsModule {
  constructor() {
    // Register all icons to be available globally in the application
    addIcons({
      // Common interface/navigation icons
      'home': home,
      'person': person,
      'person-outline': personOutline,
      'person-circle-outline': personCircleOutline,
      'log-in-outline': logInOutline,
      'log-out-outline': logOutOutline,
      'settings': settings,
      'search': search,
      'notifications': notifications,
      'menu-outline': menuOutline,
      'close-outline': closeOutline,
      'add': add,
      'remove': remove,
      'chevron-back': chevronBack,
      'chevron-forward': chevronForward,
      'chevron-down': chevronDown,
      'chevron-up': chevronUp,
      'arrow-back': arrowBack,
      'arrow-forward': arrowForward,
      'arrow-down': arrowDown,
      'arrow-up': arrowUp,
      'arrow-up-outline': arrowUpOutline,
      'arrow-back-outline': arrowBackOutline,
      'arrow-forward-outline': arrowForwardOutline,
      'arrow-down-outline': arrowDownOutline,
      'chevron-down-outline': chevronDownOutline,
      'arrow-back-circle-outline': arrowBackCircleOutline,
      'arrow-forward-circle-outline': arrowForwardCircleOutline,
      'stats-chart': statsChart,
      'stats-chart-outline': statsChartOutline,
      'library': library,
      'heart': heart,
      'mail': mail,
      'pulse-outline': pulseOutline,
      'pulse': pulse,
      'file-tray-outline': fileTrayOutline,
      'arrow-down-circle-outline': arrowDownCircleOutline,
      'medical-outline': medicalOutline,
      'chatbox-ellipses-outline': chatboxEllipsesOutline,

      // Status/notification icons
      'checkmark-circle-outline': checkmarkCircleOutline,
      'alert-circle-outline': alertCircleOutline,
      'information-circle-outline': informationCircleOutline,
      'warning-outline': warningOutline,
      'help-circle-outline': helpCircleOutline,

      // Action icons
      'create-outline': createOutline,
      'trash-outline': trashOutline,
      'save-outline': saveOutline,
      'refresh-outline': refreshOutline,
      'download-outline': downloadOutline,
      'share-outline': shareOutline,
      'document-outline': documentOutline,
      'add-circle-outline': addCircleOutline,
      'archive-outline': archiveOutline,

      // Communication icons
      'mail-outline': mailOutline,
      'call-outline': callOutline,
      'chatbubble-outline': chatbubbleOutline,
      'print-outline': printOutline,
      'ellipse-outline': ellipseOutline,
      'send-outline': sendOutline,
      'folder-open-outline': folderOpenOutline,

      // Other useful icons
      'time-outline': timeOutline,
      'calendar-outline': calendarOutline,
      'location-outline': locationOutline,
      'camera-outline': cameraOutline,
      'image-outline': imageOutline,
      'filter-outline': filterOutline,
      'flag-outline': flagOutline,
      'folder-outline': folderOutline,
      'people-outline': peopleOutline,
      'pricetag-outline': pricetagOutline,
      'reload-outline': reloadOutline,
      'lock-closed-outline': lockClosedOutline,
      'lock-open-outline': lockOpenOutline,
      'bulb-outline': bulbOutline,
      'globe-outline': globeOutline,
      'sunny-outline': sunnyOutline,
      'moon-outline': moonOutline,
      'volume-medium-outline': volumeMediumOutline,
      'volume-low-outline': volumeLowOutline,
      'volume-high-outline': volumeHighOutline,
      'volume-off-outline': volumeOffOutline,
      'volume-mute-outline': volumeMuteOutline,
      'chevron-forward-outline': chevronForwardOutline,
      'chevron-back-outline': chevronBackOutline,
      'star-outline': starOutline,
      'earth-outline': earthOutline,
      'eye': eye,
      'folder-open': folderOpen,

      // Tab icons (from the existing code)
      'triangle': triangle,
      'ellipse': ellipse,
      'square': square
    });
  }
}
